import { useEffect, useMemo, useState } from 'react';
import { Asset, FileManipulationOptions, SideWithAsset } from 'shared-types';

import useSessionStore from 'hooks/stores/useSessionStore';

import { createPreviewPdf } from 'utils/pdf';

type IUseEditor = {
  pdfFile: Blob | undefined;
  newActions: SideWithAsset['actions'];
  metaData?: Asset['metadata'];
  dimensions: Asset['dimensions'];
  cropArea: SideWithAsset['cropArea'];
};

const defaultActions = { rotation: 0, flip: false, type: 'CROP', place: 'GROSS' };

const useEditor = ({ pdfFile, newActions, dimensions, cropArea, metaData }: IUseEditor) => {
  //const [generatedPdf, setGeneratedPdf] = useState<Blob>(pdfFile as Blob);
  const [generatedPdf, setGeneratedPdf] = useState<Blob | undefined>(pdfFile);
  const session = useSessionStore((state) => state.session);
  const productSize = session?.productSize;
  const bleed = session?.bleed;

  useEffect(() => {
    console.log('useEditor input values:', {
      hasFile: !!pdfFile,
      actions: newActions,
      metadata: metaData,
      dimensions,
      cropArea,
      rotation: newActions.rotation,
    });
  }, [pdfFile, newActions, metaData, dimensions, cropArea]);

  // when crop area is set use the default actions to generate the pdf
  const actions = cropArea ? defaultActions : newActions;

  const generatedFile = useMemo(async () => {
    if (!pdfFile) return;
    try {
      console.log('Crooooop Area', cropArea);
      console.log({
        productSize,
        dimensions,
        bleed,
        ...actions,
        cropArea,
        assetMetaData: metaData,
      });
      const file = await createPreviewPdf(pdfFile, {
        productSize,
        dimensions,
        bleed,
        ...actions,
        cropArea,
        assetMetaData: metaData,
      } as FileManipulationOptions);
      return file;
    } catch (e) {
      console.error('Error while generating the file', e);
    }
  }, [pdfFile, actions, cropArea, bleed, dimensions, metaData, productSize]);

  useEffect(() => {
    generatedFile.then((file) => {
      if (file) {
        setGeneratedPdf(file);
      }
    });
  }, [generatedFile]);

  return generatedPdf;
};

export default useEditor;
