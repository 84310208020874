import { useQuery } from 'react-query';
import { Button, Card, Col, Empty, Result, Row, Spin } from 'antd';
import { isEmpty, isNil } from 'ramda';
import { SideWithAsset } from 'shared-types';

import AssetsSidebar from 'components/AssetsSidebar/AssetsSidebar';
import PageCardEditor from 'components/Book/PageCardEditor';
import ProductSidesPreviewsList from 'components/Book/PagesList';
import ResultOutput from 'components/Book/ResultOutput';
import Box from 'components/Box/Box';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';
import useQueryParams from 'hooks/useQueryParams';

//import useRealTimeUpdateSidePreview from 'hooks/useRealTimeUpdateSidePreview';
import API from 'services/API';

import { SContainer, SLoaderContainer } from './Uploader.styles';

const Book = () => {
  const { sessionId } = useQueryParams();
  const { products, selectedSideId, setProducts } = useProductsStore((state) => {
    return {
      products: state.products,
      selectedSideId: state.selectedSideId,
      setProducts: state.setProducts,
    };
  });

  const { setSessionId, setSession } = useSessionStore((state) => {
    return {
      setSessionId: state.setSessionId,
      setSession: state.setSession,
      session: state.session,
    };
  });

  const { isLoading: isLoadingProducts, isError: isProductsError } = useQuery(
    'products',
    () => API.getProducts(sessionId),
    {
      enabled: !!sessionId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setProducts(data);
      },
    },
  );

  const { isLoading, isError: isSessionError } = useQuery(
    'session',
    () => API.getSession(sessionId),
    {
      enabled: !!sessionId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setSessionId(sessionId);
        setSession(data);
      },
    },
  );

  //const { error, isVisible } = useRealTimeUpdateSidePreview();

  const sides = (products[0]?.sides as SideWithAsset[]) ?? [];
  const shouldShowBook = !isEmpty(sides) && !isNil(sides[0]?.assetId);
  const firstPageIndex = 0; // default to first page
  const foundSideIndex = sides.findIndex((side) => side.id === selectedSideId);
  const selectedSideIndex = foundSideIndex > -1 ? foundSideIndex : firstPageIndex;

  if (isLoading || isLoadingProducts) {
    return (
      <SLoaderContainer>
        <Spin size="large" />
      </SLoaderContainer>
    );
  }

  if (isSessionError || isProductsError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Send an Email</Button>}
      />
    );
  }

  return (
    <SContainer>
      <Box>
        <Row gutter={[10, 10]}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            lg={{ span: 7 }}
            style={{ height: '95vh', overflow: 'auto' }}
          >
            <AssetsSidebar
              showInstructions={false}
              showAddBlankPage={true}
              shouldFetchAssets={false}
              autoScrollDependency={sides}
            >
              <ProductSidesPreviewsList sides={sides} />
            </AssetsSidebar>
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 12 }}>
            <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
              {shouldShowBook ? (
                <PageCardEditor side={sides[selectedSideIndex]} />
              ) : (
                <Empty description="No files uploaded!" />
              )}
            </Card>
          </Col>
          {shouldShowBook && (
            <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 5 }}>
              <ResultOutput
                side={sides[selectedSideIndex]}
                totalPageCount={sides.length}
                sideNumber={selectedSideIndex + 1}
              />
            </Col>
          )}
        </Row>
      </Box>
    </SContainer>
  );
};
export default Book;
