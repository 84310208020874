import axios, { AxiosRequestConfig } from 'axios';
import { ActionsXorCropArea, Asset, Product, ProductWithSides, Session, Side } from 'shared-types';

const BASE_URL = process.env.REACT_APP_PRINT_API_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN as string;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!config.headers) {
    config.headers = {};
    config.headers['Authorization'] = AUTH_TOKEN;
  } else {
    config.headers['Authorization'] = AUTH_TOKEN;
  }
  return config;
});

// type Response<T> = {
//   meta: {
//     status: number;
//     message: string;
//   };
//   data: T;
// };

type API_TYPE = {
  getSession: (sessionId: string) => Promise<Session>;
  getProducts: (sessionId: string) => Promise<ProductWithSides[]>;
  addProduct: (sessionId: string) => Promise<Product>;
  updateProductCount: (sessionId: string, productId: string, count: number) => Promise<Product>;
  deleteProduct: (sessionId: string, productId: string) => Promise<any>;
  removeAssetFromSide: (sessionId: string, sideId: Side['id']) => Promise<Side>;
  deleteSide: (sessionId: string, sideId: Side['id']) => Promise<Side>;
  addAssetToSide: (sessionId: string, assetId: Asset['id'], sideId: Side['id']) => Promise<Side>;
  getAssets: (sessionId: string) => Promise<Asset[]>;
  updatePageNumber: (sessionId: string, assetId: Asset['id'], pageNumber: number) => Promise<Asset>;
  swapPageNumbers: ({
    sessionId,
    sideId,
    targetSideId,
  }: {
    sessionId: string;
    sideId: Side['id'];
    targetSideId: Side['id'];
  }) => Promise<Asset[]>;
  updateSide: (
    sessionId: string,
    sideId: Side['id'],
    actionsOrCropArea: ActionsXorCropArea,
  ) => Promise<Side>;
  deleteAsset: (sessionId: string, assetId: string) => Promise<any>;
  postGenerateProductionFiles: (sessionId: string) => Promise<any>;
  postGeneratePreviewForCrop: (
    sessionId: string,
    SideId: Side['id'],
  ) => Promise<{ previewCropImageUrl: string }>;
  getSignedUrl: (sessionId: string, file: File) => Promise<string>;
  deleteAllSides: (sessionId: string) => Promise<any>;
  postNewUploadMessage: (sessionId: string, file: File) => Promise<any>;
  postCreateBlankPage: (sessionId: string) => Promise<any>;
  confirmSession: (sessionId: string, { confirmed }: { confirmed: boolean }) => Promise<any>;
};

const API: API_TYPE = {
  getSession: async (sessionId) => {
    try {
      const response = await axiosInstance.get(`/sessions/${sessionId}`);
      const configData: Session = response.data.data;
      if (!configData) {
        throw new Error('Invalid or expired session');
      }
      return configData;
    } catch (e) {
      throw new Error('Invalid or expired session');
    }
  },
  getProducts: async (sessionId) => {
    const response = await axiosInstance.get(`/${sessionId}/products`);
    const products: ProductWithSides[] = response.data.data;
    return products;
  },
  addProduct: (sessionId) => {
    return axiosInstance.post(`/${sessionId}/products`).then(({ data }) => {
      return data;
    });
  },
  updateProductCount: (sessionId, productId, count) => {
    return axiosInstance
      .put(`/${sessionId}/products/${productId}`, {
        count,
      })
      .then(({ data }) => {
        return data;
      });
  },
  deleteProduct: (sessionId, productId) => {
    return axiosInstance.delete(`/${sessionId}/products/${productId}`).then(({ data }) => {
      return data;
    });
  },
  addAssetToSide: (sessionId, assetId, sideId) => {
    return axiosInstance
      .post(`/${sessionId}/products/sides/${sideId}/assets/${assetId}`)
      .then(({ data }) => {
        return data;
      });
  },
  updatePageNumber: (sessionId, assetId, pageNumber) => {
    return axiosInstance
      .put(`/${sessionId}/assets/${assetId}`, {
        pageNumber,
      })
      .then(({ data }) => {
        return data;
      });
  },
  swapPageNumbers: ({ sessionId, sideId, targetSideId }) => {
    return axiosInstance
      .put(`/${sessionId}/products/sides/swap`, {
        sideId,
        targetSideId,
      })
      .then(({ data }) => {
        return data;
      });
  },
  updateSide: (sessionId, sideId, data) => {
    return axiosInstance
      .put(`${BASE_URL}/${sessionId}/products/sides/${sideId}`, {
        ...data,
      })
      .then(({ data }) => {
        return data.data;
      });
  },
  removeAssetFromSide: (sessionId, sideId) => {
    return axiosInstance
      .delete(`/${sessionId}/products/sides/${sideId}/assets`)
      .then(({ data }) => {
        return data;
      });
  },
  deleteSide: (sessionId, sideId) => {
    return axiosInstance.delete(`/${sessionId}/products/sides/${sideId}`).then(({ data }) => {
      return data;
    });
  },
  deleteAllSides: (sessionId) => {
    return axiosInstance.delete(`/${sessionId}/products/sides`).then(({ data }) => {
      return data;
    });
  },
  getAssets: (sessionId) => {
    return axiosInstance.get(`/${sessionId}/assets`).then(({ data }) => {
      const assets: Asset[] = data.data;
      return assets;
    });
  },
  deleteAsset: (sessionId, assetId) => {
    return axiosInstance.delete(`/${sessionId}/assets/${assetId}`).then(({ data }) => {
      return data.data;
    });
  },
  postGenerateProductionFiles: (sessionId) => {
    return axiosInstance.post(`/${sessionId}/production-files`).then(({ data }) => {
      return data;
    });
  },
  postGeneratePreviewForCrop: (sessionId, SideId) => {
    return axiosInstance
      .post(`/${sessionId}/products/sides/${SideId}/cropPreview`)
      .then(({ data }) => {
        return data.data;
      });
  },
  getSignedUrl: async (sessionId: string, file: File) => {
    const response = await axiosInstance.post(`/${sessionId}/uploads/signed-url`, {
      fileName: file.name,
      fileType: file.type,
      sessionId,
    });
    const result = response.data.data;
    return result.signedUrl;
  },
  postNewUploadMessage: (sessionId: string, file: File) => {
    return axiosInstance.post(`/${sessionId}/messages/newUpload`, {
      message: {
        file: {
          fileName: file.name,
          fileType: file.type,
        },
      },
    });
  },
  postCreateBlankPage: (sessionId: string) => {
    return axiosInstance.post(`/${sessionId}/messages/createBlankPage`);
  },
  confirmSession: async (sessionId: string, { confirmed }) => {
    return axiosInstance
      .post(`/sessions/${sessionId}/confirm`, {
        confirmed,
      })
      .then(({ data }) => {
        return data;
      });
  },
};

export default API;
