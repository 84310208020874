import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ClipLoader from 'react-spinners/ClipLoader';

// Add types to this code
type Props = {
  file?: Blob;
  loading: boolean;
  isFullPreview: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  pageWidth: number;
};

const FilePreview: FC<Props> = ({ file, loading, isFullPreview, pageWidth = 500, onClick }) => {
  const ref = useRef(null);
  const isLoadingFile = !file || loading;
  const [width, setWidth] = useState(pageWidth);
  const scale = 0.7; // adjust this as needed

  useEffect(() => {
    /**
     * Handles the resize event and adjusts the width of the component accordingly.
     */
    const handleResize = () => {
      let drawWidth = window.innerWidth;
      let drawHeight = window.innerHeight;
      let width = drawWidth;
      let height = width / scale;

      if (width > drawWidth || height > drawHeight) {
        height = drawHeight;
        width = height * scale;
      }

      setWidth(width);
    };

    // Initial resize
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener('resize', handleResize);
    };
  }, [scale]); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
      ref={ref}
      onClick={onClick}
    >
      {isLoadingFile ? (
        <ClipLoader color="#35b3ee" />
      ) : isFullPreview ? (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={width} scale={scale} />
        </Document>
      ) : (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={pageWidth} />
        </Document>
      )}
    </div>
  );
};

export default FilePreview;
